<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-form ref="form">
      <v-card>
        <edit-header :dialog-title="dialogTitle" />

        <v-card-text>
          <validation-observer ref="observer">
            <v-container>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select v-model.trim="type" :items="contactType" label="Contact Type" return-object />
                </v-col>
                <v-col cols="12" sm="8">
                  <validation-provider v-slot="{ errors }" name="Contact" rules="required|max:50">
                    <v-text-field
                      v-model="contactInfo"
                      label="Contact Info"
                      prepend-icon="mdi-account-box"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </validation-observer>
        </v-card-text>
        <v-divider />
        <edit-actions :disabled-submit="true" @exit="exit" @submit="onSubmit" />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import EditHeader from './_CommonEditHeader.vue'
import EditActions from './_CommonEditActions.vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { max } from 'vee-validate/dist/rules'

extend('max', {
  ...max,
  message: '{_field_} must not exceed {length} characters.',
})

export default {
  components: {
    EditHeader,
    EditActions,
    ValidationObserver,
    ValidationProvider,
  },
  props: ['visible', 'selectedItem', 'dialogTitle'],
  data() {
    return {
      databaseModule: 'driverContactInfo',
      id: '',
      type: {},
      contactInfo: '',
      driverId: '',
      contactType: [
        { value: 1, text: 'E-mail' },
        { value: 2, text: 'Phone Number' },
      ],
    }
  },
  computed: {
    dialog: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('closeEdit', this.selectedItem)
        }
      },
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.updateInputs()
      }
    },
  },
  methods: {
    submit() {
      this.selectedItem.id = this.id
      this.selectedItem.type = this.type.value
      this.selectedItem.typeText = this.type.text
      this.selectedItem.contactInfo = this.contactInfo

      var item = Object.assign({}, this.selectedItem)
      delete item.action
      var action = ''
      if (this.selectedItem.action === 'edit') {
        action = this.databaseModule + '/updateItem'
      } else if (this.selectedItem.action === 'add') {
        action = this.databaseModule + '/addItem'
      } else {
        throw 'Unexpected action in edit dialog'
      }

      this.$store
        .dispatch(action, item)
        .then(() => {
          //action is unchanged (add, edit)
          this.dialog = false
        })
        .catch((err) => {
          this.$store.dispatch('setError', err)
        })
    },
    onSubmit() {
      this.$refs.observer.validate().then((success) => {
        if (success) this.submit()
      })
    },
    exit() {
      this.selectedItem.action = 'exit'
      this.dialog = false
    },
    updateInputs() {
      if (this.selectedItem.action === 'edit') {
        this.id = this.selectedItem.id
        this.type = this.contactType.find((elem) => elem.value === this.selectedItem.type)
        this.contactInfo = this.selectedItem.contactInfo
      } else {
        this.id = ''
        this.type = this.contactType[0]
        this.contactInfo = ''
        requestAnimationFrame(() => {
          this.$refs.observer?.reset()
        })
      }
      this.driverId = this.selectedItem.driverId
    },
  },
}
</script>
